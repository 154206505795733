import React, {Fragment} from 'react';
import Device from "./react-device-frame";

export default function VirtualTestDrive(props) {
  return (<Fragment>
    {!!props.phone ? <div className="d-none d-md-block">
      <Device use={'iphone-x'}>
        <iframe title={"virtual test drive"} width="100%" height="100%" src="https://appmana.com/watch/virtualtestdrive"
                referrerPolicy="same-origin"></iframe>
      </Device>
    </div> : <div className="d-block d-md-none" style={{height: '100%'}}>
      <iframe title={"virtual test drive"} width="100%" height="100%" src="https://appmana.com/watch/virtualtestdrive"
              referrerPolicy="same-origin"></iframe>
    </div>}
  </Fragment>)
}
