import React from 'react';
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroH1,
  HeroP,
  HeroContent,
  HeroLogo,
  HeroMessage,
} from './HeroElements';
import VideoH264 from '../assets/video/bg-h264.mp4';
import VideoH265 from '../assets/video/bg-h265.mp4';

export default function Hero(props) {
  return (
    <HeroContainer>
      <HeroBg>
        <VideoBg autoPlay loop muted playsInline>
          <source src={VideoH265} type="video/mp4; codecs=hevc"/>
          <source src={VideoH264} type="video/mp4; codecs=avc1"/>
        </VideoBg>
      </HeroBg>
      <HeroContent>
        <HeroH1>{props.title}</HeroH1>
        <HeroP>{props.body}</HeroP>
      </HeroContent>
      <HeroLogo />
      <HeroMessage>Portfolio upon request</HeroMessage>
    </HeroContainer>
  );
}
