import styled from 'styled-components';
import LogoSrc from '../assets/images/HSlogo2.png';
import React from "react";
import atariPalette from "./AtariPalette";

export const HeroContainer = styled.div`
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 800px;
  position: relative;
  background-color: ${atariPalette.paletteSky1Color4.rgba};
  z-index: 1;
`;

export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
`;

export const VideoBgMobileAdjusted = styled.video`
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  @media screen and (max-width: 480px) {
    object-position: 55% center;
    height: 440px
  }
`

export const HeroContent = styled.div`
  color: white;
  z-index: 2;
  max-width: 1200px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeroH1 = styled.h1`
  font-weight: bolder;
  font-size: 43px;
  line-height: 43px;
  text-align: center;
  max-width: 736px;
`;

export const HeroP = styled.p`
  display: flex;
  margin-top: 24px;
  font-weight: normal;
	font-size: 18px;
	padding: 0 40px;
  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
  line-height: 22px;
  max-width: 736px;
  text-align: center;
`;

export const HeroLogo = () => {
    const style = {
        zIndex: 3,
        width: '63px',
        height: '63px',
        position: 'absolute',
        left: '0',
        bottom: '0',
        margin: '0 0 60px 60px',
    }
    return <img className="d-none d-sm-block" src={LogoSrc} style={style} width={63} height={63}/>;
};

export const HeroMessage = styled.h3`
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 0 60px 60px 0;
  font-weight: bold;
`;

export const Modal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
