import React, {Fragment, useEffect, useRef, useState} from 'react';
import Unity, {UnityContext} from "react-unity-webgl";
import LoaderUrl from '../items-portfolio/010-propaganda-tennis/game/Build/game.loader'
import FrameworkUrl from '../items-portfolio/010-propaganda-tennis/game/Build/game.framework'
import CodeUrl from '../items-portfolio/010-propaganda-tennis/game/Build/game.wasm'
import DataUrl from '../items-portfolio/010-propaganda-tennis/game/Build/game.data'
import Device from "./react-device-frame";
import {Overlay, ProgressBar} from "react-bootstrap";

const unityContext = new UnityContext({
  loaderUrl: LoaderUrl,
  dataUrl: DataUrl,
  frameworkUrl: FrameworkUrl,
  codeUrl: CodeUrl,
});

export default function PropagandaTennis(props) {
  const [show, setShow] = useState(true)
  const showProgressBar = props.progress

  useEffect(() => {
    unityContext.on('progress', progression => {
      setShow(progression >= 1.0)
    })
  })
  const target = useRef(null);
  const progressBar = (
    showProgressBar ? <Overlay target={target.current} show={show} placement="">
      {({ placement, arrowProps, show: _show, popper, ...props }) => (
        <div
          {...props}
          style={{
            backgroundColor: 'rgba(255, 100, 100, 0.85)',
            padding: '2px 10px',
            color: 'white',
            borderRadius: 3,
            ...props.style,
          }}
        >
          Simple tooltip
        </div>
      )}
    </Overlay> : <Fragment/>
  )

  return (<Fragment>
    {!!props.phone ? <div className="d-none d-md-block">
      <Device use={'iphone-x'}>
        {progressBar}
        <Unity ref={target} unityContext={unityContext} style={{width: '375px', height: '812px'}}/>
      </Device>
    </div> : <div className="d-block d-md-none" style={{height: '100%'}}>
      {progressBar}
      <Unity ref={target} unityContext={unityContext} style={{width: '100%', height: '100%'}}/>
    </div>}
  </Fragment>)
}
