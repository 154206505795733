import React from 'react'
import {Col, Container, Row} from "react-bootstrap";

export const Spacer = () => (<Col xs={{span: 0}} lg={{span: 2}} xxl={{span: 3}} className="p-0 g-0"/>);

export const JumboAdjustingItem = ({children}) => {
  return <Container fluid>
    <Row>
      <Spacer />
      <Col xs={{span: 12}} lg={{span: 8}}  xxl={{span: 6}}>
        {children}
      </Col>
      <Spacer />
    </Row>
  </Container>
}
