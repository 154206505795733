import React from "react";
import Hero from "../components/Hero";
import {Layout} from "../components/Layout";
import styled from "styled-components";
import PropagandaTennis from "../components/PropagandaTennis";
import {Button, Col, Container, Row} from "react-bootstrap";
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage, getImage, StaticImage} from "gatsby-plugin-image"
import {navigate} from "gatsby-link";
import {RowClassesAlignCenter, RowClassesJustifyContentEvenly} from "../components/RowStyles";
import {BgImage} from 'gbimage-bridge';
import * as flexBasisDetail from '../components/FlexBasisDetail.module.css'
import {
  DarkBackgroundHeader,
  DarkBackgroundParagraph,
  DarkOnLightColor,
  DarkOnLightHeader,
  DarkOnLightParagraph,
  DarkOnLightRow,
  DarkRow
} from "../components/Rows";
import {VideoBgMobileAdjusted} from "../components/HeroElements";
import InceptionVideo from "../items-portfolio/005-netflix-vr-wormwood/inception.mp4";
import RealtimeVideo from "../items-portfolio/013-rumsfeld/photoreal.mp4";
import {JumboAdjustingItem} from "../components/JumboAdjustingItem";
import VirtualTestDrive from "../components/VirtualTestDrive";

const MonsterMatchImage = '../items-portfolio/001-monster-match/Monster Match Image_00000.png'
const SpellsourceImage = '../items-portfolio/012-spellsource/Spellsource Image_00000.png'
const PropagandaTennisImage = '../items-portfolio/010-propaganda-tennis/Tennis Image_00000.png'
const MinionateImage = '../items-portfolio/014-minionate/Minionate Hero@4x.png'
const gameImageWidthHeight = 400;
const isBrowser = typeof window !== "undefined"

const title = 'We build next generation interactive digital experiences';
const body =
  'We are a full service creative team with a talented network of designers, engineers, and strategists.';

const DarkAnchor = styled.a`
  color: ${DarkOnLightColor};

  :hover {
    color: ${DarkOnLightColor}
  }
`

const ClientsRow = styled(DarkRow)`
  min-height: 380px;
`

const SizedDarkOnLightRow = styled(DarkOnLightRow)`
  min-height: 480px;
  @media (min-width: 576px) {
    min-height: 600px
  }
`


const Index = () => {
  const {logos, spellsourceWorldMapImage} = useStaticQuery(graphql`
    query Images {
      spellsourceWorldMapImage: file(absolutePath: {regex: "/heroes.png$/i"}) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            quality: 100
            formats: [AUTO]
          )
        }
      }
      logos: allFile(filter: {absolutePath: {regex: "/items-logos/i"}}) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(
                placeholder: NONE
                layout: FIXED
                quality: 100
                width: 120
                formats: [AUTO]
              )
            }
            name
            absolutePath
          }
        }
      }
    }
  `)

  const isMobile = isBrowser ? /(iPhone)|(iPad)|(Android)/.test(navigator.userAgent) : false;
  return (<Layout>
    <Hero title={title} body={body}/>
    <ClientsRow className={RowClassesAlignCenter}>
      <Col md={{span: 3}}>
        <h3>Our clients</h3>
      </Col>
      <Col md={{span: 9}}>
        <JumboAdjustingItem>
          <Container fluid
                     className="d-flex justify-content-start justify-content-md-center align-items-center align-content-between flex-wrap p-0 g-0">
            {logos.edges.map(edge => (
              <GatsbyImage className="m-2"
                           style={{
                             display: 'inline-flex'
                           }}
                           imgStyle={{filter: 'invert(1)'}}
                           key={edge.node.name}
                           alt={edge.node.name}
                           image={getImage(edge.node)}/>
            ))}
          </Container>
        </JumboAdjustingItem>
      </Col>
    </ClientsRow>
    <SizedDarkOnLightRow className={RowClassesAlignCenter}>
      <Col md={{span: 3}}>
        <h3>What we make</h3>
      </Col>
      <Col md={{span: 9}}>
        <JumboAdjustingItem>
          <h2 className="text-start text-md-center">Games and applications using the latest rendering and interaction
            technologies,
            delivered to the browser, the in-app social media feed, the VR headset and the native mobile App Stores</h2>
        </JumboAdjustingItem>
      </Col>
    </SizedDarkOnLightRow>
    <DarkRow className="p-0 g-0">
      <span className="d-block d-md-none"><BgImage image={getImage(spellsourceWorldMapImage)}
                                                   style={{minHeight: 200, height: 780}}>
        <Container fluid className="d-flex align-items-end p-4 p-lg-5 g-0" style={{height: "100%"}}>
          <Col sm={{span: 4}} lg={{span: 3}} className="p-0 g-0"><DarkOnLightHeader>From concept to
            deployment</DarkOnLightHeader>
            <DarkOnLightParagraph>Our team does the entire process with you</DarkOnLightParagraph></Col>
        </Container>
      </BgImage></span>
      <Container fluid className="d-none d-md-flex p-4 p-lg-5 g-0">
        <Col sm={{span: 4}} lg={{span: 3}} className="p-0 g-0">
          <h3>From concept to deployment</h3>
          <p>Work with our team throughout the entire process to develop gameplay, characters,
            locations, props and assets for everything from abstractly stylized to fully photoreal
            games.</p>
        </Col>
        <Col sm={{span: 8}} lg={{span: 9}}>
          <GatsbyImage alt="Concept" image={getImage(spellsourceWorldMapImage)}/>
        </Col>
      </Container>
    </DarkRow>
    <Row className="p-0 g-0" style={{minHeight: 400}}>
      <VideoBgMobileAdjusted autoPlay loop muted playsInline>
        <source src={InceptionVideo} type="video/mp4; codecs=avc1"/>
      </VideoBgMobileAdjusted>
      <Container style={{position: 'absolute'}} fluid className={RowClassesAlignCenter}>
        <Col sm={{span: 4}} lg={{span: 3}} className="p-0 g-0"><DarkBackgroundHeader>We push the
          envelope</DarkBackgroundHeader>
          <DarkBackgroundParagraph>Pictured: <span className="d-none d-md-inline">A promotional virtual reality experience for</span><span
            className="d-inline d-md-none">A VR experience for</span> Netflix's <span
            style={{fontStyle: "italic"}}>Wormwood</span> <span className="d-none d-md-inline">that real-time simulates the experience of a bad psychedelic trip from the perspective of the show's subject, Frank Olson, who murdered by his CIA handlers while experimenting with LSD.</span>
          </DarkBackgroundParagraph></Col>
      </Container>
    </Row>
    <Row className="p-0 g-0" style={{minHeight: 400}}>
      <VideoBgMobileAdjusted autoPlay loop muted playsInline>
        <source src={RealtimeVideo} type="video/mp4; codecs=avc1"/>
      </VideoBgMobileAdjusted>
      <Container style={{position: 'absolute'}} fluid className={RowClassesAlignCenter}>
        <Col sm={{span: 4}} lg={{span: 3}} className="p-0 g-0"><DarkBackgroundHeader>We do
          photoreal</DarkBackgroundHeader>
          <DarkBackgroundParagraph>Pictured: <span className="d-none d-md-inline">A realtime photoreal snowglobe interactive promoting</span><span
            className="d-inline d-md-none">An interactive for</span> Participant's <span
            style={{fontStyle: "italic"}}>Unknown Known</span> <span className="d-none d-md-inline">using our
          proprietary experience streaming platform to bring realtime raytraced photoreal effects to mobile devices</span>
          </DarkBackgroundParagraph></Col>
      </Container>
    </Row>
    <DarkOnLightRow className="d-flex align-items-center p-0 g-0">
      <Col sm={{span: 6}} xl={{span: 3}} className="pt-4 ps-4 pe-4 pe-sm-0 p-lg-5 pe-lg-0 g-0">
        <DarkOnLightHeader>Selected games</DarkOnLightHeader>
        <DarkOnLightParagraph><DarkAnchor href={"https://www.playspellsource.com"}>Spellsource</DarkAnchor>, <DarkAnchor
          href={"https://monstermatch.hiddenswitch.com"}>Monster Match</DarkAnchor>, Minionate and Propaganda Tennis
          are just a few games we're excited to share with you.</DarkOnLightParagraph>
        <DarkOnLightParagraph>Outside of these public games, Hidden Switch has developed confidential games in
          photorealistic,
          realtime-raytraced styles.</DarkOnLightParagraph>
      </Col>
      <Col xl={{span: 9}}>
        <Container fluid
                   className="d-flex justify-content-md-end align-items-center align-content-between flex-wrap p-0 "
                   style={{maxWidth: 2 * gameImageWidthHeight + 96, marginLeft: "auto", marginRight: 0}}>
          <StaticImage onClick={() => navigate("https://www.playspellsource.com")}
                       style={{display: 'inline-flex', cursor: "pointer"}}
                       src={SpellsourceImage} alt={"Spellsource"}
                       width={gameImageWidthHeight}
                       height={gameImageWidthHeight} className={flexBasisDetail.flexBasisStretch}/>
          <StaticImage onClick={() => navigate("https://monstermatch.hiddenswitch.com")}
                       style={{display: 'inline-flex', cursor: "pointer"}} src={MonsterMatchImage} alt={"Monster Match"}
                       width={gameImageWidthHeight} height={gameImageWidthHeight}
                       className={flexBasisDetail.flexBasisStretch}/>
          <StaticImage style={{display: 'inline-flex'}} src={MinionateImage}
                       alt={"Minionate"} width={gameImageWidthHeight} height={gameImageWidthHeight}
                       className={flexBasisDetail.flexBasisStretch}/>
          <StaticImage style={{display: 'inline-flex'}} src={PropagandaTennisImage}
                       alt={"Propaganda Tennis"} width={gameImageWidthHeight} height={gameImageWidthHeight}
                       className={flexBasisDetail.flexBasisStretch}/>
        </Container>
      </Col>
    </DarkOnLightRow>
    <DarkRow className={RowClassesJustifyContentEvenly}>
      <Col md={{span: 4}}>
        <Container fluid>
          <h3>Technology that ships</h3>
          <p>Hidden Switch specializes in end-user products that ship and scale to the widest possible audiences. Our
            demos focus on devices people actually have and use, like smartphones, networked over the Internet
            connections they have everywhere, like LTE, with completely frictionless access and high fidelity within
            native apps and mobile browsers.</p>
          <h3>You'll have fun</h3>
          <p>We stand by every interactive and emphatically deliver only experiences you'll enjoy. You can rely
            on our team of expert engineers, artists, writers and musicians to develop a concept people will love.</p>
          <h3>Try our demos</h3>
          <p>You're encouraged to try the Hyundai Virtual Test Drive. Tap Drive and use touch to drive the car in this
            totally original design for a test drive experience. This was visited by millions since 2021.
          </p>
          <Container fluid className="d-flex d-md-none">
            <Button onClick={() => navigate('https://appmana.com/watch/virtualtestdrive')}>Play Now</Button>
          </Container>
        </Container>
      </Col>
      <Col md={{span: 8}}>
        {isMobile ? <div/> : <Container fluid className="d-none d-md-flex justify-content-md-end">
          <VirtualTestDrive phone={true}/>
        </Container>}
      </Col>
    </DarkRow>
  </Layout>)
}

export default Index
